import React, { useEffect, useState } from 'react';
import ZipCode from '../Home/ZipModal';
import PropTypes from 'prop-types';
import RenderIf from '../../Utility/RenderIf';
import { useNavigate, Link } from 'react-router-dom';
import * as _ from 'lodash';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, getToken } from '../../utils/token';
import { Button } from 'react-bootstrap';

export default function Header(props) {
  const supressHeaderOptions = window.location.pathname === '/packages';
  const orderPage = window.location.pathname === '/packages' || window.location.pathname === '/storage-box/buying-box';
  // const showAuthButtons = window.location.pathname === '/storage-boxes';
  const showAuthButtons = window.location.pathname.startsWith('/storage-box');
  const element = document.querySelector('._access-icon.material-icons');
  useEffect(() => {

    console.log(element, orderPage)
    if (element) {
      if (orderPage) {
        element.style.opacity = '0';
      } else {
        element.style.opacity = '1';
      }
    }
  }, [orderPage])
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const [openZipModal, setZipModal] = React.useState(false);

  const [toggleModal, setToggleModal] = React.useState(true);

  const { user } = useSelector((state) => state.auth);
  const loginToken = getToken();

  const logout = async () => {
    dispatch({ type: 'SET_CUSTOMER', data: {} });
    clearUser();
    navigate('/');
  };

  const hideZipM = () => {
    setZipModal(false);
  };

  const showZipModal = () => {
    setZipModal(true);
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }

  const items = [
    // {
    //   label: '•  How it Works',
    //   path: '/moving-boxes',
    //   key: '1'
    // },
    {
      label: '•  Moving Supplies',
      path: '/movingsupplies',
      key: '2'
    },
    {
      label: '•  Packing Supplies',
      path: '/packingsupplies',
      key: '3'
    },
    {
      label: '•  Pricing',
      path: '/pricing',
      key: '4'
    }
  ];

  const handleMenuClick = (e) => {
    e?.domEvent?.stopPropagation();
    let menuItem = _.find(items, { key: e.key });
    if (menuItem) {
      navigate(menuItem.path);
    }
    setMenuOpen(false);
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <>
      <ZipCode
        showModal={openZipModal}
        hideModal={hideZipM}
        toggleModal={toggleModal}
        toggleModalFunction={toggleModalFunction}
        showHideHeader={props.showHideHeader}
        setShowHideHeader={props.setShowHideHeader}
        showHideFooter={props.showHideFooter}
        setshowHideFooter={props.setshowHideFooter}
        showHideinnerFooter={props.showHideinnerFooter}
        setshowHideinnerFooter={props.setshowHideinnerFooter}
        storeStatus={props.storeStatus}
        storeStatusMessage={props.storeStatusMessage}

      //  values={props.values}
      //  setValues={props.setValues}

      //  zipcode={zipcode}
      //  setZipcode={setZipcode}
      />

      <div className={supressHeaderOptions ? 'header' : 'header'}>
        <nav
          className={
            supressHeaderOptions
              ? 'navbar navbar-expand-lg navbar-light  px-0  justify-content-center'
              : 'navbar navbar-expand-lg navbar-light  px-0 '
          }>
          <Link className="navbar-brand" to="/">
            <img
              src="img/blueboxlogo.png"
              className="logo img-fluid"
              alt="somethinglogo"
              width="100"
              height="100"
            />
          </Link>
          {!supressHeaderOptions ? (
            <>
              <button
                className="navbar-toggler"
                onClick={(e) => {
                  setMenuOpen(!menuOpen);
                }}>
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={` ${menuOpen ? ' collapse navbar-collapse show' : 'collapse navbar-collapse '
                  }`}
                id="navbarSupportedContent"
                hidden={supressHeaderOptions}>
                <ul
                  className="navbar-nav ml-auto align-items-start"
                  onClick={() => navigate('/moving-boxes')}>
                  <li className="nav-item mt-2">
                    <Dropdown
                      menu={{
                        items,
                        onClick: handleMenuClick
                      }}
                      onOpenChange={handleOpenChange}
                      open={open}>
                      <a
                        onClick={(e) => {
                          setOpen(!open);
                        }}>
                        <Space className="align-content-center">
                          <span className="text-white fs-15 mr-1">Rent Moving Boxes</span>
                          {open ? (
                            <CaretUpOutlined style={{ color: 'white' }} />
                          ) : (
                            <CaretDownOutlined style={{ color: 'white' }} />
                          )}
                        </Space>
                      </a>
                    </Dropdown>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white fs-15"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                        navigate('/storage-boxes');
                      }}>
                      Storage On-Demand
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white fs-15"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                        navigate('/location');
                      }}>
                      Service Area
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white fs-15"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                        navigate('/contactus');
                      }}>
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link text-white fs-15 nj-recommendation"
                      href="https://nicejob.com/bluebox-moving-box-rentals-storage-on-demand-campbell-ca/recommend">
                      Recommend Us!
                    </a>
                  </li>

                  {/* <li className="nav-item">
                        <a className="nav-link text-dark fs-15" href="../faq">
                          FAQ
                        </a>
                      </li> */}
                  {/* <li className="nav-item" hidden={!loginToken}> */}
                  <li className="nav-item" hidden={!showAuthButtons || !loginToken}>
                    <a
                      className="nav-link text-white cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                        navigate('/storage-box/my-account?tab=account');
                      }}>
                      <img
                        className="img-fluid"
                        src="svg/my-account.svg"
                        style={{ height: '19px' }}
                      />
                      <span className="fs-15"> My Account</span>
                    </a>
                  </li>
                  {/* <li className="nav-item" hidden={loginToken}> */}
                  <li className="nav-item" hidden={!showAuthButtons || loginToken}>
                    <a
                      className="nav-link text-white cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setMenuOpen(false);
                        navigate('/storage-box/login');
                      }}>
                      <img className="img-fluid mr-1" src="svg/logout.svg" width="19" height="19" />
                      <span className="fs-15">Login</span>
                    </a>
                  </li>
                  {/* <li className="nav-item" hidden={!loginToken}> */}
                  <li className="nav-item" hidden={!showAuthButtons || !loginToken}> 
                    <a className="nav-link text-white cursor-pointer" onClick={logout}>
                      <img className="img-fluid mr-1" src="svg/logout.svg" width="19" height="19" />
                      <span className="fs-15">Logout</span>
                    </a>
                  </li>
                  <li className="nav-item order-now" style={{ width: '100%' }}>
                    <a
                      className="nav-link text-white py-1 btn btn-dark fs-15 fw-500"
                      style={{ lineHeight: '31px', borderRadius: '5px' }}
                      onClick={() => {
                        showZipModal();
                        setToggleModal(true);
                      }}>
                      Order Now
                    </a>
                  </li>
                </ul>
              </div>
            </>
          ) : null}
        </nav>
      </div>
    </>
  );
}

Header.propTypes = {
  showHideHeader: PropTypes.any,
  setShowHideHeader: PropTypes.any,
  showHideFooter: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  showHideinnerFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  showZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any,
  supressHeaderOptions: PropTypes.any
};
