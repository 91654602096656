import axios from 'axios';
import { clearUser, getToken } from '../utils/token';
import { notification } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';


const API_URL = process.env.REACT_APP_API_URL;
const instance = axios.create({
    baseURL: API_URL,
    // withCredentials: false,
    // headers: {
    //     "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // },
});

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.patch['Content-Type'] = 'application/json';
// instance.defaults.headers.common['Authorization'] = AUTH_TOKEN

// ####################################################################################################################
// Node Instance Initialization
const NODE_API_URL = process.env.REACT_APP_NODE_API_URL;
const StorageInstance = axios.create({
    baseURL: NODE_API_URL,
    withCredentials: false,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
});

// StorageInstance.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response.status === 401) {
//             clearUser();

//             notification.error({
//                 message: 'Your session has expired',
//                 description: 'Redirecting to login page, Please login again...!',
//                 icon: <ClockCircleOutlined style={{ color: '#ff4d4f' }} />,
//                 duration: 3,
//                 onClose: () => {
//                     // Redirect to login page here
//                     window.location.href = window.location.protocol + "//" + window.location.host + '/storage-box/login';
//                 },
//                 placement: 'middle'
//             });

//         }

//         return Promise.reject(error)
//     }
// )
// StorageInstance.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response) {
//       const { status } = error.response;
      
//       if (status === 401) {
//         clearUser();

//         notification.error({
//           message: 'Your session has expired',
//           description: 'Redirecting to login page, Please login again...!',
//           duration: 60,
//           onClose: () => {
//             // window.location.href = `${window.location.protocol}//${window.location.host}/storage-box/login`;
//           },
//           placement: 'middle'
//         });

//       }
//     //   else if (status === 400) {
//     //     notification.error({
//     //       message: 'Bad Request',
//     //       description: 'The server could not understand the request due to invalid syntax.',
//     //       duration: 3,
//     //       placement: 'middle'
//     //     });
//     //   }

//     } else {
//       notification.error({
//         message: 'Network Error',
//         description: 'There was an issue with your network connection.',
//         duration: 3,
//         placement: 'middle'
//       });
//     }

//     return Promise.reject(error);
//   }
// );


StorageInstance.interceptors.request.use(
    async (config) => {
        const token = await localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

StorageInstance.defaults.headers.post['Content-Type'] = 'application/json';
StorageInstance.defaults.headers.patch['Content-Type'] = 'application/json';
// StorageInstance.defaults.headers.common['Authorization'] = getToken();

export { instance, StorageInstance };