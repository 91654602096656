import React, { useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { Link, useNavigate } from 'react-router-dom';
import ZipCode from '../../ZipModal';

import PropTypes from 'prop-types';
import RenderIf from '../../../../Utility/RenderIf';

import { Input, Collapse, Button } from 'antd';
const { Panel } = Collapse;

import {
  getHomeData,
  getTestimonials,
  getContactInfo,
  getWhyUs,
  getLocations,
  checkZipCode,
  saveQuote,
  getFaq,
  validateStorageZipCode
} from '../../../../data/API';

import toast from 'react-hot-toast';

import SimpleSlider from '../../slider';

import './index.css';
import { useDispatch } from 'react-redux';
import { getToken } from '../../../../utils/token';
import { setPincode } from '../../../StorageBox/store/actionCreators';
import { Col, Row } from 'react-bootstrap';
import useGetPrice from '../../../../hooks/useGetPrice';
import { Helmet } from 'react-helmet';

export default function StorageBoxes(props) {
  const [mainloader, setMainloader] = useState(false);
  const [errorMsg, setMsg] = useState('');
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const dispatch = useDispatch();

  const [home, setHomeData] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [why_us, setWhyUs] = useState([]);
  const [contact_info, setContactInfo] = useState([]);
  const [locations, setLocation] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [faq, setFaq] = useState([]);
  const [area, setArea] = useState('');
  const [isInputTouched, setIsInputTouched] = useState(false);
  const loginToken = getToken();
  const { boxPrices } = useGetPrice();
  console.log(boxPrices, 'hello');

  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    delivery_date: '',
    pickup_zipcode: '',
    delivery_zipcode: ''
  });

  const getFaqsData = () => {
    getFaq('storage-on-demand')
      .then((res) => {
        setFaq(res.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getFaqsData();
  }, []);

  useEffect(() => {
    var cancel = false;
    props.setshowZipModal(true);

    getHomeData()
      .then((res) => {
        if (cancel) return;
        setHomeData(res.data);
      })
      .catch((e) => console.log(e));

    getWhyUs()
      .then((res) => {
        if (cancel) return;
        setWhyUs(res.data);
      })
      .catch((e) => console.log(e));

    getTestimonials()
      .then((res) => {
        if (cancel) return;
        setTestimonials(res.data);
      })
      .catch((e) => console.log(e));

    getLocations()
      .then((res) => {
        if (cancel) return;
        setLocation(res.data);
      })
      .catch((e) => console.log(e));

    if (zipcode === '') {
      setZipcode(false);
    }

    return () => {
      cancel = true;
    };
  }, []);

  const [showM, setModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const hideZModal = () => {
    setModal(false);
  };

  const showZModal = () => {
    setModal(true);
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }

  const checkValidArea = async () => {
    setMsg('');
    setMainloader(true);
    let payload = {
      deliveryZipCode: area
    };

    let validateRes = await validateStorageZipCode(payload);

    if (validateRes?.data?.data?.serviceExist) {
      setMainloader(false);
      dispatch(setPincode(values.delivery_zipcode));
      // if (user?.name) {
      if (loginToken) {
        navigate('/storage-box/buying-box');
      } else {
        navigate('/storage-box/login');
      }
    } else {
      setError(true);
      setMainloader(false);
      setMsg('Service not available in this area.');
    }
  };
  return (
    <>
      <Helmet>
        <title>Storage Services in the San Francisco Bay Area | BlueBox</title>
        <meta
          name="description"
          content="Free initial box delivery, easy inventory management and on-demand access to your stored items via our app or website. Only reusable & clean boxes"
        />
        <meta
          name="keywords"
          content=" storage San francisco, storage San Jose, storage Oakland, storage on demand san francisco
"
        />
      </Helmet>
      <div className="storage-boxes">
        <ZipCode
          showModal={toggleModal}
          hideModal={hideZModal}
          zipcode={zipcode}
          setZipcode={setZipcode}
          toggleModal={toggleModal}
          toggleModalFunction={toggleModalFunction}
          storeStatus={props.storeStatus}
          storeStatusMessage={props.storeStatusMessage}
          service_type="STORAGE_ON_DEMAND"
        />
        {/* <!-- hero --> */}
        <section className="hero position-relative banner-background">
          <div className="container">
            <div className="row">
              <div className="col-md-12 position-relative home-banner">
                <h1 className="mb-0 font-weight-bold banner-text aos-init aos-animate">
                  Say goodbye to cluttered spaces with BlueBox&apos;s Storage On-Demand service
                </h1>

                <h4 className="text-white text-center mt-3">
                  Get organized and simplify your life with our convenient Storage On-Demand
                  service. Free initial box delivery, easy online inventory management, and
                  on-demand access to your stored items.
                </h4>
                <div className="btns mt-4">
                  <div
                    className="row d-flex justify-content-center"
                    style={{ width: '100%', padding: 0, margin: 0 }}>
                    <div className="">
                      <button
                        className="btn btn-primary w-100 d-block aos-init aos-animate"
                        // data-toggle="modal"
                        // data-target="#zipModal"
                        onClick={() => {
                          showZModal();
                          setToggleModal(true);
                        }}>
                        Start Your Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How it works */}
        <section className="columns py-3 bg-light-blue" style={{ borderRadius: '0' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-center mb-4 mt-4">
                <p className="text-md-blue mb-4 fw-700  section-title">How it Works</p>
                <p className="text sub-description fs-12 section-sub-description">
                  Gone are the days of renting a storage unit and lugging things back and forth.
                  We’ll do that for you.
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/free-delivery-circle-new.png"
                  className="oneImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">Request storage boxes</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  We’ll provide free delivery of our clean, plastic storage boxes right to your
                  door.
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/moving-supplies-circle-new.png"
                  className="twoImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">Pack your stuff</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  Pack your items into the boxes. Think of us as an extension of your home or
                  office.
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/next-day-delivery-circle.png"
                  className="threeImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">Schedule a pick-up</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  We’ll pick up your boxes and our driver will take an overhead photo of each open
                  box for you to inventory once uploaded to your account.
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/next-day-delivery-circle@3x.png"
                  className="fourImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">We deliver</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  Login to your online account to request a box delivery, and get your stuff back
                  when you need it
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* How it works */}

        {/* Service Info */}
        <section className="service-info d-flex justify-content-center align-items-center flex-column pb-5">
          <div>
            <p className="service-info-title fw-600">Why Choose BlueBox? </p>
          </div>
          <table className="table table-bordered data-table">
            <thead style={{ background: '#1D2024' }}>
              <tr>
                <th></th>
                <th className="text-center">
                  <img src="img/Color-black-bg@2x.png" />
                </th>
                <th className="text-center pt-0 pb-0">
                  <img src="img/storage_graphic.png" style={{ height: '46px', width: '40px' }} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Free initial box delivery</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Online inventory</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Photos of stored items</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>At-home deliveries</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Pay only for the boxes you use (not unused storage space)</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Save time and money</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Convenient</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Short and long-term options</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mt-5">
            {boxPrices?.length > 0 ? (
              <p className="fs-15 fw-400 text-center">
                Storage rates are just $9 box/month with a three month minimum.{' '}
                <Link to={'/contactus'}>Contact us </Link>if you need storage for a shorter amount
                of time.
              </p>
            ) : (
              ''
            )}
          </div>
        </section>
        {/* Service Info */}

        <section className="control-storage bg-light-blue">
          <div className="content">
            <div className="row lear-more-content-row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-content pr-0">
                <p className="fs-24 fw-600 mb-title">Take control of your stored items!</p>
                <p className="fs-15 fw-400 mb-description">
                  Easily view and organize your stored items from any device. Delivery of boxes is
                  just a few clicks away, and you’ll never have to wonder what’s in a box again.
                </p>
                <div className="storage-image-mt">
                  <img className="img img-fluid" src="img/storage-interface.png" />
                </div>
                <button
                  className="btn theme-btn btn-create-act"
                  onClick={() => navigate('/storage-box/register')}>
                  Create your account
                </button>
              </div>
              <div className="col-lg-6 col-md-2 col-sm-12 col-xs-12 pt-0 storage-image-desktop">
                <img className="img img-fluid" src="img/storage-interface.png" />
              </div>
            </div>
          </div>
        </section>

        <div className="container-fluid" style={{ background: 'white', paddingTop: '50px' }}>
          <div className="row section-ml mt-4">
            <div className="col-md-6 col-sm-12 d-flex justify-content-end about-image">
              <img
                className="app-section-img-lg"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section.png"
                alt=""
              />
              <img
                className="app-section-img-sm"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section-sm.png"
                alt=""
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center fs-23 about-text">
              <p className="about-us-heading fw-600 d-center">
                Download our app
                <div className="land-divider"></div>
              </p>

              <p className="mt-3 text-left">
                Stress-free moving and storage solutions in the palm of your hand
              </p>
              <div className="row mt-3">
                <div className="col-md-3  app-download-button">
                  <img src="img/GooglePlay.png" alt="" />
                </div>
                <div className="col-md-3  app-download-button">
                  <img src="img/AppStore.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row section-xs " style={{ marginTop: '10px', paddingBottom: '30px' }}>
            <p className="about-us-heading fw-600 d-center">
              Download our app
              <div className="land-divider"></div>
            </p>
            <p className="mt-3 text-center">
              Stress-free moving and storage solutions in the palm of your hand
            </p>

            <div className="col-12  d-flex justify-content-end about-image">
              <img
                className="app-section-img-lg"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section.png"
                alt=""
              />
              <img
                className="app-section-img-sm"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section-sm.png"
                alt=""
              />
            </div>
            <div className="col-12  d-flex flex-column justify-content-center fs-23 about-text">
              <div className="row mt-3">
                <div className="col-md-6 col-sm-12 app-download-button">
                  <img src="img/GooglePlay.png" alt="" />
                </div>
                <div className="col-md-6 col-sm-12 app-download-button app-store-btn">
                  <img src="img/AppStore.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
        {/* <ZipCode showModal={showM} hideModal={hideZModal} /> */}

        {/* <!-- slider Section --> */}
        <section className="sliderSection py-3 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="sliderSection-heading">
                  <h5 className="sliderSection-title">{`Don't take it from us...`}</h5>
                  <h2 className="sliderSection-subTitle">
                    {`Here’s what our`}
                    <br /> customers are
                    <br /> saying
                  </h2>
                </div>
              </div>
              <div className="col-md-8">
                <div className="slider">
                  <SimpleSlider testimonials={testimonials} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- slider Section --> */}

        {/* FAQ */}

        <section className="faq-container pl-3 pr-3 " style={{ paddingTop: '50px' }}>
          <p className="text-center fs-20 fw-500 mt-3">FAQ</p>
          <div className="faq-content">
            <div className="accordion" id="accordionExample">
              {faq.results?.map((obj) => {
                // {console.log(obj)}
                return (
                  <>
                    <div className="card_main">
                      <div className="card">
                        <div
                          className="card-header collapsed d-flex align-items-center justify-content-between"
                          data-toggle="collapse"
                          data-target={`#collapseOne${obj.id}`}
                          aria-expanded="false">
                          <div>
                            <span className="title fs-15">{obj.question}</span>
                          </div>
                          <div>
                            <span className="accicon text-right">
                              <i className="fas fa-angle-down rotate-icon icon-down-arrow"></i>
                            </span>
                          </div>
                        </div>
                        <div
                          id={`collapseOne${obj.id}`}
                          className="collapse fs-14"
                          data-parent="#accordionExample">
                          <div
                            className="card-body answer-content"
                            dangerouslySetInnerHTML={{
                              __html: obj.answer
                            }}></div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>

        {/* END FAQ */}
        {/* <!-- we deliver to this area --> */}
        <section className="locationiframe">
          <iframe
            src="https://www.google.com/maps/d/embed?mid=12kcjTorPJsZapSyYTn6pryBGaOazmiPn"
            width="640"
            height="480"
            title="location"></iframe>
        </section>

        <section className="get-started px-5">
          <Row className="justify-content-center">
            <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={12}>
              <label>
                <span className="text-danger m-0 p-0">* </span>Zip code where you’d like storage
                boxes delivered
              </label>
              <Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={12} className="get-started-row">
                  <Input
                    size="large"
                    value={area}
                    onChange={(evt) => {
                      setArea(evt.target.value);
                      setMsg('');
                    }}
                    placeholder="Enter Zip code"
                    onBlur={() => setIsInputTouched(true)}
                  />
                  {area.length == 0 && isInputTouched ? (
                    <p style={{ color: 'red' }}>Please enter a valid zipcode. </p>
                  ) : (
                    ''
                  )}
                  {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={12} className="get-started-row">
                  <Button
                    size="large"
                    className="theme-btn btn-get-started w-100"
                    disabled={mainloader}
                    onClick={() => checkValidArea()}>
                    {mainloader ? 'Processing...' : 'Get started'}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>

        <br />
        {/* <!-- we deliver to this area --> */}
      </div>
    </>
  );
}
StorageBoxes.propTypes = {
  setShowHideHeader: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  setshowZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
