import React, {useState} from 'react';
import {useDispatch} from "react-redux";


function PasswordPrompt () {
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    const currentHostName = process.env.REACT_APP_HOST_URI;
    const currentPassword = process.env.REACT_APP_HOST_PASSWORD;

    console.log(currentHostName, "=======currentHostName==")
    console.log(currentPassword, "===currentPassword===")


    const handleSubmit = () => {
        const hostname = window.location.hostname
        console.log(hostname, "hostname")
        if (hostname === currentHostName && password === currentPassword) {
            console.log("inisde if===========")
            dispatch({ type: 'SET_AUTH', data: {password} });
        } else {
            console.log("inisde else===========")
            setError("Password is not valid")
        }
    };
    return (
            <div className="modal fade show d-flex justify-content-center align-items-center bg-white overflow-hidden" tabIndex="-1" role="dialog">
                <div className="modal-dialog h-100 w-100 d-flex align-items-center" role="document">
                    <div className="modal-content">
                        <div className="modal-header d-block text-center">
                            <h5 className="modal-title">Verify with Password</h5>
                        </div>
                        <div className="modal-body h-auto">
                            <label htmlFor="password">Password</label>
                            <input name={"password"} id={"password"} onChange={(e)=>{setPassword(e.target.value)}} type="password"/>
                            <div>
                                <p className={"mb-0 text-danger"}>{error}</p>
                            </div>
                        </div>
                        <div className="modal-footer d-block text-center">
                            <button onClick={handleSubmit} type="button" className="btn btn-primary">Verify</button>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default PasswordPrompt;