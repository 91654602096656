import { useRoutes } from 'react-router-dom';
import Home from './views/Home';
import Pricing from './views/Pricing';
import MovingSupplies from './views/MovingSupplies';
import PackingSupplies from './views/PackingSupplies';
import BoxPackages from './views/BoxPackages';
import Faq from './views/Faq';
import Privacypolicy from './views/Privacypolicy';
import Location from './views/Location';
import Terms from './views/Terms';
import About from './views/About';
import Contactus from './views/Contactus';
import Homemoving from './views/Homemoving';
import Officemoving from './views/Officemoving';
import RenderIf from './Utility/RenderIf';
import Packages from './views/Packages';
import OrderConfirmation from './views/OrderConfirmation';
import ErrorPage404 from './views/404';
import MovingBoxesLandingPage from './views/Home/LandingPages/MovingBoxes';
import StorageBoxesLandingPage from './views/Home/LandingPages/StorageBoxes';
import Register from './views/StorageBox/Auth/Register';
import Login from './views/StorageBox/Auth/Login';
import { element } from 'prop-types';
import ConfirmEmail from './views/StorageBox/Auth/EmailConfirmation/ConfirmEmail';
import EmailConfirmed from './views/StorageBox/Auth/EmailConfirmation/EmailConfirmed';
import PasswordSuccess from './views/StorageBox/Auth/ResetPassword/ResetSuccess';
import RequestReset from './views/StorageBox/Auth/ResetPassword/Request';
import ConfirmPassword from './views/StorageBox/Auth/ResetPassword/Confirm';
import HowItWorks from './views/StorageBox/HowItWorks';
import BuyingBox from './views/StorageBox/BuyingBox';
import Dashboard from './views/StorageBox/Dashboard';
import ReqDeliveryDetails from './views/StorageBox/Dashboard/Request';
import Offers from "./views/Offers";
import Blog from "./views/Blog";
import BlogDetails from "./views/Blog/components/BlogDetails";
import San_Jose_landing_page from './views/Home/LandingPages/SanJose';
import Oakland_landing_page from './views/Home/LandingPages/Oakland';
import SanMateo_landing_page from './views/Home/LandingPages/SanMateo';
import SanFrancisco_landing_page from './views/Home/LandingPages/SanFrancisco';
import Sunnyvale_landing_page from './views/Home/LandingPages/Sunnyvale';
import Fremont_landing_page from './views/Home/LandingPages/Fremont';

const routesList = ({
  setshowZipModal,
  zipCodeSection,
  scrollToSection,
  showFooteroffice,
  setFooteroffice,
  setShowHideHeader,
  showHideFooter,
  setshowHideFooter,
  showHideinnerFooter,
  storeStatus,
  storeStatusMessage,
  showHideHeader,
  setshowHideinnerFooter,
  onEnter,
  setSuppressHeaderOptions
}) => [
  {
    path: '',
    element: (
      <Home
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },
    {
    path: '/san-francisco',
    element: (
      <SanFrancisco_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },
  {
    path: '/oakland',
    element: (
      <Oakland_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },
  {
    path: '/san-mateo',
    element: (
      <SanMateo_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },
  {
    path: '/san-jose',
    element: (
      <San_Jose_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },

    {
    path: '/fremont',
    element: (
      <Fremont_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },

    {
    path: '/sunnyvale',
    element: (
      <Sunnyvale_landing_page
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
    },


  {
    path: '/moving-boxes',
    element: (
      <MovingBoxesLandingPage
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
  },
  {
    path: '/storage-boxes',
    element: (
      <StorageBoxesLandingPage
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
  },
  {
    path: 'pricing',
    element: (
      <Pricing
        setshowZipModal={setshowZipModal}
        zipCodeSection={zipCodeSection}
        scrollToSection={scrollToSection}
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        showHideHeader={showHideHeader}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
        setshowHideinnerFooter={setshowHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
  },
  {
    path: 'MovingSupplies',
    element: <MovingSupplies storeStatus={storeStatus} storeStatusMessage={storeStatusMessage} />
  },
  {
    path: 'Location',
    element: (
      <Location
        setShowHideHeader={setShowHideHeader}
        setshowHideFooter={setshowHideFooter}
        setshowHideinnerFooter={setshowHideFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
  },
  {
    path: 'PackingSupplies',
    element: <PackingSupplies storeStatus={storeStatus} storeStatusMessage={storeStatusMessage} />
  },
  {
    path: 'Faq',
    element: <Faq />
  },
  {
    path: 'popup',
    element: <Offers />
  },
  {
    path: 'Privacypolicy',
    element: (
      <Privacypolicy
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
      />
    )
  },
  {
    path: 'Contactus',
    element: (
      <Contactus
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideHeader={showHideHeader}
        setShowHideHeader={setShowHideHeader}
        showHideFooter={showHideFooter}
      />
    )
  },
  {
    path: 'Terms',
    element: (
      <Terms
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
      />
    )
  },
  {
    path: 'Homemoving',
    element: <Homemoving storeStatus={storeStatus} storeStatusMessage={storeStatusMessage} />
  },
  {
    path: 'Officemoving',
    element: (
      <Officemoving
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
        storeStatus={storeStatus}
        storeStatusMessage={storeStatusMessage}
      />
    )
  },
  // {
  //   path: 'box-packages',
  //   element: (
  //     <RenderIf isTrue={storeStatus === 'open'}>
  //       <BoxPackages
  //         showHideHeader={showHideHeader}
  //         setShowHideHeader={setShowHideHeader}
  //         showHideFooter={showHideFooter}
  //         setshowHideFooter={setshowHideFooter}
  //         showHideinnerFooter={showHideinnerFooter}
  //         setshowHideinnerFooter={setshowHideinnerFooter}
  //         onEnter={onEnter}
  //       />
  //     </RenderIf>
  //   )
  // },
  {
    path: 'packages',
    element: (
      <RenderIf isTrue={storeStatus === 'open'}>
        <Packages
          showHideHeader={showHideHeader}
          setShowHideHeader={setShowHideHeader}
          showHideFooter={showHideFooter}
          setshowHideFooter={setshowHideFooter}
          showHideinnerFooter={showHideinnerFooter}
          setshowHideinnerFooter={setshowHideinnerFooter}
          onEnter={onEnter}
          setSuppressHeaderOptions={setSuppressHeaderOptions}
        />
      </RenderIf>
    )
  },
  {
    path: 'About',
    element: (
      <About
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
      />
    )
  },
  {
    path: 'blog',
    children: [
      {
        path: '',
        element: (
            <Blog

            />
        )
      }, {
        path: ':id',
        element: (
            <BlogDetails

            />
        )
      }]
  },
  {
    path: 'order_confirmation/:orderId',
    element: (
      <OrderConfirmation
        showFooteroffice={showFooteroffice}
        setFooteroffice={setFooteroffice}
        setshowHideFooter={setshowHideFooter}
        showHideinnerFooter={showHideinnerFooter}
      />
    )
  },
  {
    path: '404',
    element: (
      <ErrorPage404
        setShowHideHeader={setShowHideHeader}
        setshowHideFooter={setshowHideFooter}
        setshowHideinnerFooter={setshowHideFooter}
      />
    )
  },
  {
    path: '*',
    element: (
      <ErrorPage404
        setShowHideHeader={setShowHideHeader}
        setshowHideFooter={setshowHideFooter}
        setshowHideinnerFooter={setshowHideFooter}
      />
    )
  },
  {
    path: 'storage-box',
    children: [
      {
        path: 'register',
        element: (
          <Register
            setShowHideHeader={setShowHideHeader}
            setshowHideFooter={setshowHideFooter}
            setshowHideinnerFooter={setshowHideinnerFooter}
          />
        )
      },
      {
        path: 'login',
        element: (
          <Login
            setShowHideHeader={setShowHideHeader}
            setshowHideFooter={setshowHideFooter}
            setshowHideinnerFooter={setshowHideinnerFooter}
          />
        )
      },
      {
        path: 'email',
        children: [
          {
            path: 'confirmation',
            element: (
              <ConfirmEmail
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          },
          {
            path: 'confirmed',
            element: (
              <EmailConfirmed
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          }
        ]
      },
      {
        path: 'reset-password',
        children: [
          {
            path: '',
            element: (
              <ConfirmPassword
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          },
          {
            path: 'generate',
            element: (
              <RequestReset
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          },
          {
            path: 'success',
            element: (
              <PasswordSuccess
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          }
        ]
      },
      {
        path: 'how-it-works',
        element: (
          <HowItWorks
            setShowHideHeader={setShowHideHeader}
            setshowHideFooter={setshowHideFooter}
            setshowHideinnerFooter={setshowHideinnerFooter}
          />
        )
      },
      {
        path: 'buying-box',
        element: (
          <BuyingBox
            setShowHideHeader={setShowHideHeader}
            setshowHideFooter={setshowHideFooter}
            setshowHideinnerFooter={setshowHideinnerFooter}
          />
        )
      },
      {
        path: 'my-account',
        children: [
          {
            path: '',
            element: (
              <Dashboard
                setShowHideHeader={setShowHideHeader}
                setshowHideFooter={setshowHideFooter}
                setshowHideinnerFooter={setshowHideinnerFooter}
              />
            )
          },
          {
            path: 'request-delivery',
            element: <ReqDeliveryDetails />
          }
        ]
      }
      
    ]
  }
];

const AppRoutes = (props) => {
  const routes = useRoutes(routesList(props));
  return routes;
};

export default AppRoutes;
