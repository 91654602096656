import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FilledBox from '../../../assets/images/filled_box.png';
import Storage from '../../../assets/images/storage.png';
import BlogCard from './BlogCard';
import { useDispatch, useSelector } from 'react-redux';
import { checkZipCode, getBlogById } from '../../../data/API';
import SimpleReactValidator from 'simple-react-validator';
import {
  currentURL,
  facebookShareURL,
  twitterShareURL,
  whatsappShareURL
} from '../../../utils/socialLinks';
import { Popover } from 'antd';
const storage_url =
  process.env.REACT_APP_AWS_STORAGE || 'https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-s3';
const BlogDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { blogs } = useSelector((state) => state.blog);
  const [blogData, setBlogData] = useState();
  const [loading, setLoading] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [errorMsg, setMsg] = useState('');
  const [isError, setError] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };
  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    delivery_date: '',
    pickup_zipcode: '',
    delivery_zipcode: ''
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    const item = {
      value: true,
      expiry: new Date().getTime()
    };
    localStorage.setItem('zipcode', JSON.stringify(item));
  };

  const handleZipCode = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      e.preventDefault();

      let obj = {
        pickup_zipcode: values.pickup_zipcode,
        delivery_zipcode: values.delivery_zipcode
      };
      checkZipCode(obj)
        .then((res) => {
          if (res?.data?.success) {
            navigate('/packages');
          } else {
            setError(true);
            setMsg(res?.data?.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      e.preventDefault();
      validator.current.showMessages();
      return false;
    }
  };
  const fetchBlogsDetails = async (id) => {
    try {
      const { data } = await getBlogById(id);
      if (data?.success) {
        setBlogData(data?.data);
      } else {
        dispatch({ type: 'RESET_REDUX_STORE' });
        navigate('/404');
      }
    } catch (error) {
      dispatch({ type: 'RESET_REDUX_STORE' });
      navigate('/404');
    }
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchBlogsDetails(id);
  }, [id]);
  return (
    <div className="blogPost">
      <div className="container">
        <div className="row">
          {loading ? (
            <div className="col-xl-9 col-lg-8 d-flex justify-content-center mt-20">
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="spinner-grow text-primary mr-2" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="col-xl-9 col-lg-8">
              <div className="blogPost-content">
                <div className="blogDetailsCard">
                  <div className="blogDetailsCard-img">
                    <img
                      src={`${storage_url}/${blogData?.blog_image}`}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="blogDetailsCard-info">
                    <div className="blogDetailsCard-heading">
                      <h4 className="blogDetailsCard-title">{blogData?.blog_title}</h4>
                      <h5 className="blogDetailsCard-time">
                        {new Date(blogData?.created_on)
                          ?.toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          })
                          .replace(/,/g, '')}
                      </h5>
                    </div>
                    <div className="blogDetailsCard-content">
                      <p className="blogCard-text">{blogData?.blog_short_description}</p>
                      <div
                        className="blog-card-description"
                        dangerouslySetInnerHTML={{ __html: blogData?.blog_description }}></div>

                      {/*<h4>Heading Line</h4>*/}
                      {/*<p>*/}
                      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ornare*/}
                      {/*  mauris. Integer iaculis sed tortor in vulputate. Nam lobortis justo et justo*/}
                      {/*  porttitor convallis nec in lacus. Morbi ornare maximus nisi et facilisis.*/}
                      {/*  Proin porttitor scelerisque dapibus. Praesent congue non nisi in dignissim.*/}
                      {/*  Mauris mi lacus, dapibus at pharetra et, faucibus vitae massa Lorem ipsum*/}
                      {/*  dolor sit amet, consectetur adipiscing elit. Ut vitae ornare mauris. Integer*/}
                      {/*  iaculis sed tortor in vulputate. Nam lobortis justo et justo porttitor*/}
                      {/*  convallis nec in lacus. Morbi ornare maximus nisi et facilisis. Proin*/}
                      {/*  porttitor scelerisque dapibus. Praesent congue non nisi in dignissim. Mauris*/}
                      {/*  mi lacus, dapibus at pharetra et, faucibus vitae massa*/}
                      {/*</p>*/}
                      {/*<h4>Heading Line</h4>*/}
                      {/*<p>*/}
                      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ornare*/}
                      {/*  mauris. Integer iaculis sed tortor in vulputate. Nam lobortis justo et justo*/}
                      {/*  porttitor convallis nec in lacus. Morbi ornare maximus nisi et facilisis.*/}
                      {/*  Proin porttitor scelerisque dapibus. Praesent congue non nisi in dignissim.*/}
                      {/*  Mauris mi lacus, dapibus at pharetra et, faucibus vitae massa*/}
                      {/*</p>*/}
                      {/*<h4>Heading</h4>*/}
                      {/*<img src={banner} alt="" className="img-fluid" />*/}
                      {/*<h5>Heading 2</h5>*/}
                      {/*<p>*/}
                      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ornare*/}
                      {/*  mauris. Integer iaculis sed tortor in vulputate. Nam lobortis justo et justo*/}
                      {/*  porttitor convallis nec in lacus. Morbi ornare maximus nisi et facilisis.*/}
                      {/*  Proin porttitor scelerisque dapibus. Praesent congue non nisi in dignissim.*/}
                      {/*  Mauris mi lacus, dapibus at pharetra et, faucibus vitae massa*/}
                      {/*</p>*/}
                      {/*<h5>Heading 2</h5>*/}
                      {/*<p>*/}
                      {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vitae ornare*/}
                      {/*  mauris. Integer iaculis sed tortor in vulputate. Nam lobortis justo et justo*/}
                      {/*  porttitor convallis nec in lacus. Morbi ornare maximus nisi et facilisis.*/}
                      {/*  Proin porttitor scelerisque dapibus. Praesent congue non nisi in dignissim.*/}
                      {/*  Mauris mi lacus, dapibus at pharetra et, faucibus vitae massa*/}
                      {/*</p>*/}
                    </div>
                    <div className="blogDetailsCard-shareBox">
                      <div className="blogDetailsCard-shareBox-title">Share this article</div>
                      <div className="blogDetailsCard-shareBox-links">
                        <a
                          href={`${facebookShareURL}/blog/${blogData?.id}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <i className="fab fa-facebook"></i>
                        </a>
                        <a
                          href={`${twitterShareURL}/blog/${blogData?.id}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <i className="fa-brands fa-x-twitter"></i>
                        </a>
                        <a
                          href={`${whatsappShareURL}/blog/${blogData?.id}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                        <Popover content="Link Copied" trigger="click" open={copySuccess}>
                          <a
                            href={`${currentURL}/blog/${blogData?.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              copyToClipboard(`${currentURL}/blog/${blogData?.id}`);
                            }}
                            target="_blank"
                            rel="noopener noreferrer">
                            <i className="fas fa-link"></i>
                          </a>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blogInfoCard">
                  <h4 className="blogInfoCard-title">
                    We make it easy and convenient to reduce your moving footprint
                  </h4>
                  <p className="blogInfoCard-text">
                    Rent reusable moving boxes for a stress-free move and simplify your life with
                    our convenient Storage On-Demand option.
                  </p>
                  <div className="blogInfoCard-list">
                    <div className="blogInfoCard-listBox">
                      <img className="img-fluid blue-box-rent" src={FilledBox} />
                      <button className="blueBBtn" onClick={() => navigate('/moving-boxes')}>
                        Rent Moving Box
                      </button>
                    </div>
                    <div className="blogInfoCard-listBox">
                      <img className="img-fluid blue-box-storage" src={Storage} />
                      <button className="blueBBtn" onClick={() => navigate('/storage-boxes')}>
                        Storage On-Demand
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-xl-3 col-lg-4">
            <div className="blogPostSidebar">
              <div className="blogPostSidebar-part">
                <h5 className="blogPostSidebar-part-title">Follow us </h5>
                <div className="social-icons">
                  <a href="https://instagram.com/rentbluebox">
                    <i className="fab fa-instagram"></i>{' '}
                  </a>
                  <a href="https://www.tiktok.com/@rentbluebox?_t=8f5AM4LnqN4&_r=1">
                    <i className="fab fa-tiktok"></i>
                  </a>
                  <a href="https://www.facebook.com/rentbluebox?mibextid=LQQJ4d">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://threads.net/@rentbluebox">
                    <i className="fa-brands fa-threads"></i>{' '}
                  </a>
                </div>
              </div>
              <div className="blogPostSidebar-part">
                <h5 className="blogPostSidebar-part-title">
                  Rent reusable moving boxes for a stress-free move!
                </h5>
                <div className="blogPostSidebar-form">
                  {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                  <div className="formControl">
                    <label>Zipcode of your current address</label>
                    <input
                      type="number"
                      value={values.pickup_zipcode}
                      placeholder="Enter Your Zip Code"
                      name="pickup_zipcode"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <div className="formControl">
                    <label>Zipcode of your new address</label>
                    <input
                      type="number"
                      value={values.delivery_zipcode}
                      placeholder="Enter Your Zip Code"
                      name="delivery_zipcode"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                  <button className="blueBBtn" onClick={(e) => handleZipCode(e)}>
                    {' '}
                    Start Your Order
                  </button>
                </div>
              </div>
              <div className="blogPostSidebar-part">
                <h5 className="blogPostSidebar-part-title">Download our app</h5>
                <div className="downloadBox">
                  <img
                    className="img-fluid"
                    src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section.png"
                    alt=""
                  />
                  <p>Stress-free, moving and storage solutions in the palm of your hand</p>
                  <div className="downloadBox-list">
                    <a href="#">
                      <img src="img/GooglePlay.png" alt="" className="img-fluid" />
                    </a>
                    <a href="https://apps.apple.com/us/app/bluebox-rent/id6450204692">
                      <img src="img/AppStore.png" alt="" className="img-fluid" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="articlesModule">
              <h2 className="articlesModule-title">Related Articles</h2>
              <div className="row">
                {blogs?.slice(0, 3).map((blog, index) => (
                  <div className="col-lg-4" key={index}>
                    <BlogCard item={blog} footer={true} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
