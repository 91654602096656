import React, { useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useNavigate } from 'react-router-dom';
import ZipCode from '../../ZipModal';
// import CommonZipCodeModal from '../../ZipModal';

import PropTypes from 'prop-types';
import RenderIf from '../../../../Utility/RenderIf';

import {
  getHomeData,
  getTestimonials,
  getContactInfo,
  getWhyUs,
  getLocations,
  checkZipCode,
  saveQuote,
  getFaq,
  getDiscount
} from '../../../../data/API';

import toast from 'react-hot-toast';

import SimpleSlider from '../../slider';

import './index.css';
import { Helmet } from 'react-helmet';
import Offers from "../../../Offers";

export default function MovingBoxes(props) {
  const [mainloader, setMainloader] = useState(false);
  const [errorMsg, setMsg] = useState('');
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  const [, forceUpdate] = useState();
  const validator = useRef(new SimpleReactValidator());
  const [faq, setFaq] = useState([]);

  const [home, setHomeData] = useState();
  const [testimonials, setTestimonials] = useState([]);
  const [why_us, setWhyUs] = useState([]);
  const [contact_info, setContactInfo] = useState([]);
  const [locations, setLocation] = useState([]);
  const [zipcode, setZipcode] = useState([]);
  const [popupData, setPopupData] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const [values, setValues] = useState({
    full_name: '',
    email: '',
    phone: '',
    address: '',
    delivery_date: '',
    pickup_zipcode: '',
    delivery_zipcode: ''
  });

  const handleLearnMore = () => {
    navigate('/storage-boxes');
  };

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    const item = {
      value: true,
      expiry: new Date().getTime()
    };
    // console.log(item.expiry);
    localStorage.setItem('zipcode', JSON.stringify(item));
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const loadiframe = () => {
    window.$('#myModal').modal('show');
    document.getElementById('iframediv').innerHTML =
      " <button type='button' className='close' onClick= window.$('#myModal').modal('hide') > <span aria-hidden='true'>&times;</span> </button>  <iframe src='https://www.google.com/maps/d/embed?mid=1tKoG-65lKUliYMUheHqFHds9xbLieQQ' width='640' height='480'></iframe>";
  };

  const handleQuoteSubmit = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      saveQuote(values)
        .then((res) => {
          toast.success('Successfully created!');
          ('Message sent successfully');
        })
        .catch((e) => {
          toast.error('Some error occure,please try again');
        });
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const handleZipCode = (e) => {
    e.preventDefault();
    setMainloader(true);
    if (validator.current.allValid()) {
      e.preventDefault();

      let obj = {
        pickup_zipcode: values.pickup_zipcode,
        delivery_zipcode: values.delivery_zipcode
      };
      checkZipCode(obj)
        .then((res) => {
          if (res?.data?.success) {
            navigate('/packages');
            props.setShowHideHeader(false);
            props.setshowHideFooter(false);
            props.setshowHideinnerFooter(true);
          } else {
            setError(true);
            setMsg(res?.data?.message);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      e.preventDefault();
      validator.current.showMessages();
      forceUpdate(1);
      return false;
    }
  };

  const getFaqsData = () => {
    getFaq('rent-moving-boxes')
      .then((res) => {
        setFaq(res.data);
      })
      .catch((e) => console.log(e));
  };
  useEffect(() => {
    getFaqsData();
  }, []);

  useEffect(() => {
    var cancel = false;
    props.setshowZipModal(true);

    getHomeData()
      .then((res) => {
        if (cancel) return;
        setHomeData(res.data);
      })
      .catch((e) => console.log(e));

    getWhyUs()
      .then((res) => {
        if (cancel) return;
        setWhyUs(res.data);
      })
      .catch((e) => console.log(e));

    getTestimonials()
      .then((res) => {
        if (cancel) return;
        setTestimonials(res.data);
      })
      .catch((e) => console.log(e));

    getLocations()
      .then((res) => {
        if (cancel) return;
        setLocation(res.data);
      })
      .catch((e) => console.log(e));

    if (zipcode === '') {
      setZipcode(false);
    }

    return () => {
      cancel = true;
    };
  }, []);

  const [showM, setModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const hideZModal = () => {
    setModal(false);
  };

  const showZModal = () => {
    setModal(true);
  };
  function toggleModalFunction(argu) {
    setToggleModal(argu);
  }

  const fetchOffer = async () => {
    let res = await getDiscount();
    setPopupData(res?.data);
    if(res?.data[0].display_location == "both" || res?.data[0].display_location == "moving_box_rentals"){
      setPopupVisible(true)
    }
  }
  useEffect(() => {
    fetchOffer()
  }, [])


  return (
    <>
      <Helmet>
        <title>Moving boxes and supplies delivered to your home or office</title>
        <meta
          name="description"
          content="BlueBox rents commercial-grade, reusable boxes to replace dirty cardboard boxes. Make your next San Francisco Bay Area move easier, cheaper and greener!"
        />
        <meta
          name="keywords"
          content="rent moving boxes San Francisco, rent moving boxes Oakland, rent moving boxes San Jose, rent moving boxes free delivery"
        />
      </Helmet>
      <div className="moving-boxes">
        <ZipCode
          showModal={toggleModal}
          hideModal={hideZModal}
          zipcode={zipcode}
          setZipcode={setZipcode}
          toggleModal={toggleModal}
          toggleModalFunction={toggleModalFunction}
          storeStatus={props.storeStatus}
          storeStatusMessage={props.storeStatusMessage}
          service_type="RENT_MOVING_BOXES"
        />
<Offers isVisible={isPopupVisible}
        onClose={handleClosePopup}
        popupData={popupData} />
        {/* <!-- hero --> */}
        <section className="hero position-relative banner-background">
          <div className="container">
            <div className="row">
              <div className="col-md-12 position-relative home-banner">
                <h1 className="mb-0 font-weight-bold banner-text aos-init aos-animate">
                  Moving boxes and supplies delivered to your home or office
                </h1>

                <h4 className="text-white text-center mt-3">
                  BlueBox rents commercial-grade, reusable boxes to replace expensive, dirty
                  cardboard boxes that often end up in landfills. Our goal is to calm the chaos and
                  reduce the stress while making your next SF Bay Area move easier, cheaper and
                  greener!
                </h4>
                <div className="btns mt-4">
                  <div
                    className="row d-flex justify-content-center"
                    style={{ width: '100%', padding: 0, margin: 0 }}>
                    <div className="">
                      <button
                        className="btn btn-primary w-100 d-block aos-init aos-animate"
                        // data-toggle="modal"
                        // data-target="#zipModal"
                        onClick={() => {
                          showZModal();
                          setToggleModal(true);
                        }}>
                        Start Your Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
              <img
                src="img/help.png"
                className="img-fluid position-absolute"
                data-aos="zoom-in"
                data-aos-duration="1500"
                alt="somethinghelping Men"
              />
            </div> */}
            </div>
          </div>
        </section>
        {/* <!-- hero --> */}
        {/* <!-- Modal --> */}
        <ZipCode showModal={showM} hideModal={hideZModal} />

        {/* <!-- We deliver  --> */}
        <section className="columns py-3 bg-light-blue" style={{ borderRadius: '0' }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 text-center mb-4 mt-4">
                <p className="text-md-blue mb-4 fw-700  section-title">How it Works</p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/next-day-delivery-circle@3x.png"
                  className="oneImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">Order boxes</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  Select from any of our moving box packages and order moving supplies, if needed.
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/free-delivery-circle-new.png"
                  className="twoImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">We deliver</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  We will provide free delivery of our clean, sanitized plastic moving boxes
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/moving-supplies-circle-new.png"
                  className="threeImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">You pack and move</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  Pack the boxes, hire movers and move into your new home or office
                </p>
              </div>
              <div className="col-md-3 p-0 py-2 howitwork mb-4 text-center">
                <img
                  src="img/free-delivery-circle-new.png"
                  className="fourImg mb-4"
                  alt="something"
                />
                <h3 className="text-md-blue mb-0 section-subtitle">We pick up</h3>
                <p className="text sub-description fs-12 section-sub-description">
                  Once you are done unpacking and settled in, we will visit to pickup the boxes
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- We deliver  --> */}

        {/* <!-- why choos us --> */}

        {/* <section className="whyChooseUs">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Why Choose BlueBox</h1>
                            </div>
                            <div className="whyChooseUs_banner">
                                <div className="row">
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-01.png" alt="" />
                                        <h4>Free Delivery & Pick Up</h4>
                                        <p className='sub-description'>
                                            We provide free delivery and pick up of our moving boxes throughout the San
                                            Francisco Bay Area and beyond
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-02.png" alt="" />
                                        <h4>Timed Deliveries</h4>
                                        <p className='sub-description'>We offer timed deliveries to ensure boxes arrive when you need them</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-03.png" alt="" />
                                        <h4>Next-Day Delivery</h4>
                                        <p className='sub-description'>
                                            Need boxes in a hurry? We offer next-day delivery. Just order by 3 PM the day
                                            before
                                        </p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-04.png" alt="" />
                                        <h4>Moving Supplies</h4>
                                        <p className='sub-description'> We sell moving supplies to help you get packing as soon as boxes arrive</p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-05.png" alt="" />
                                        <h4>Earth Friendly</h4>
                                        <p className='sub-description'>
                                            Using reusable boxes saves trees and keeps cardboard out of landfills. No dumps,
                                            no dumpsters, no waste
                                         </p>
                                    </div>
                                    <div className="col-md-4">
                                        <img src="img/homepageicons-06.png" alt="" />
                                        <h4>Stress Free</h4>
                                        <p className='sub-description'>Moving can be stressful. Packing doesn’t have to be. Pack happy!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

        {/* <!-- why choos us --> */}

        {/* Service Info */}
        <section className="service-info d-flex justify-content-center flex-column align-items-center">
          <div className="mt-4">
            <p className="section-title fw-500">Why Choose BlueBox?</p>
          </div>

          <table className="table table-bordered data-table">
            <thead style={{ background: '#1D2024' }}>
              <tr>
                <th></th>
                <th className="text-center">
                  <img src="img/Color-black-bg@2x.png" />
                </th>
                <th className="text-center pt-0 pb-0">
                  <img src="img/card-board.png" style={{ height: '46px', width: '40px' }} />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Free delivery and pick up</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Easy online ordering</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Fully assembled</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>No tape needed</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Ready to pack</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>No breakage, holes, tears</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Water resistant</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Crushproof</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Clean and sanitized</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Convenient</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Cheaper</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
              <tr>
                <td>Eco- Friendly</td>
                <td className="text-center w-20">
                  <img src="svg/circular-check.svg" />
                </td>
                <td className="text-center w-20">
                  <img src="svg/circular-close.svg" />
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {/* Service Info */}

        {/* <!-- beats cardboard box --> */}
        <section className="cardboardBox">
          <div className="container-fluid">
            <div className="cardbox">
              <div className="row">
                <div className="col-md-12">
                  <img src="img/new-boxes-desktop1.png" alt="" />
                </div>
                <div id="mydiv">
                  <img src="img/new-boxes-mobile1.png" className="image_mobile" alt="" />
                  <img src="img/new-boxes-mobile2.png" className="image_mobile" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- beats cardboard box --> */}

        {/* <!-- slider Section --> */}
        <section className="sliderSection py-3 position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="sliderSection-heading">
                  <h5 className="sliderSection-title">{`Don't take it from us...`}</h5>
                  <h2 className="sliderSection-subTitle">
                    {`Here’s what our`}
                    <br /> customers are
                    <br /> saying
                  </h2>
                </div>
              </div>
              <div className="col-md-8">
                <div className="slider">
                  <SimpleSlider testimonials={testimonials} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- slider Section --> */}

        {/* FAQ */}

        <section
          className="faq-container pl-3 pr-3 "
          style={{ paddingTop: '50px', paddingLeft: '20%', paddingRight: '20%' }}>
          <p className="text-center fs-20 fw-500 faq-title mt-3">FAQ</p>
          <div className="faq-content">
            <div className="accordion" id="accordionExample">
              {faq.results?.map((obj) => {
                // {console.log(obj)}
                return (
                  <>
                    <div className="card_main">
                      <div className="card">
                        <div
                          className="card-header collapsed d-flex align-items-center justify-content-between"
                          data-toggle="collapse"
                          data-target={`#collapseOne${obj.id}`}
                          aria-expanded="false">
                          <div>
                            <span className="title fs-15">{obj.question}</span>
                          </div>
                          <div>
                            <span className="accicon text-right">
                              <i className="fas fa-angle-down rotate-icon icon-down-arrow"></i>
                            </span>
                          </div>
                        </div>
                        <div
                          id={`collapseOne${obj.id}`}
                          className="collapse fs-14"
                          data-parent="#accordionExample">
                          <div
                            className="card-body answer-content"
                            dangerouslySetInnerHTML={{
                              __html: obj.answer
                            }}></div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </section>

        {/* <!-- we deliver to this area --> */}
        <section className="locationiframe">
          <iframe
            src="https://www.google.com/maps/d/embed?mid=12kcjTorPJsZapSyYTn6pryBGaOazmiPn"
            width="640"
            height="480"
            title="location"></iframe>
        </section>

        {/* <div className='row'>
                    <div className='col-12 nature-div d-flex flex-column text-white justify-content-center align-center text-center'>
                        <div className='pt-5 pb-5'>
                            <h1 className='fw-700 main-title'>We Value Our Community and Environment</h1>
                        </div>
                        <div className='text-center pl-5 pr-5'>
                            <h3 className='mb-0 fw-500 sub-title'>With every BlueBox order, you’re helping to reduce the tons of cardboard
                                headed to landfills, thousands of gallons of oil and water used to create cardboard,
                                and let’s not forget our beautiful trees.</h3>
                        </div>
                        <div className='pt-5 pb-4'>
                            <h2 className='fw-600 sub-title-2'>Producing one ton of cardboard requires…</h2>
                        </div>
                        <div>
                            <div className='row' style={{ flexWrap: "unset" }}>
                                <div className='col-md-4 fw-500 fs-30'>
                                    <p className='mb-0 bottom-count'>17</p>
                                    <p className='mb-0 bottom-title'>Trees</p>
                                </div>
                                <div className='col-md-4 fw-500 fs-30'>
                                    <p className='mb-0 bottom-count'>79</p>
                                    <p className='mb-0 bottom-title'>Gallons of Oil</p>
                                </div>
                                <div className='col-md-4 fw-500 fs-30'>
                                    <p className='mb-0 bottom-count'>7000</p>
                                    <p className='mb-0 bottom-title'>Gallons of Water</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}

        <RenderIf isTrue={props.storeStatus === 'open'}>
          <section className="hero move-location position-relative location custom bg-white">
            <div className="container">
              <div id="locations-content" className="ng-scope">
                <div
                  className="row align-content-center"
                  style={{
                    jstifyContent: 'center'
                  }}>
                  <div className="columns small-12 large-10 large-offset-1 end">
                    <div className="locationform mt-4">
                      {/* <h2><img width="32" src="img/icon-truck.png" data-interchange="[/assets/img/icon-truck@2x.png, (retina)]" /> Check If We Service Your Area</h2> */}

                      <div className="">
                        <div className="">
                          <div className="modal-header " style={{ borderBottom: 'none' }}>
                            <img src="img/logo.png" className="img-fluid" alt="" />
                            <h5>Where are you moving?</h5>
                            {/* <button type="button" className="close" onClick={props.hideModal}>
                                                              <span aria-hidden="true">&times;</span>
                                                          </button> */}
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              {isError ? <p style={{ color: 'red' }}>{errorMsg} </p> : ''}
                              <div className="col-md-6 p-2">
                                <span className="text-danger m-0 p-0">* </span>Zip code of your
                                current address?
                                <input
                                  type="text"
                                  id="ship-address"
                                  className="form-control"
                                  placeholder="Enter Zip code"
                                  name="pickup_zipcode"
                                  value={values.pickup_zipcode}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="col-md-6 p-2">
                                <span className="text-danger m-0 p-0">* </span>Zip code of your new
                                address?
                                <input
                                  type="text"
                                  id="pickup-address"
                                  className="form-control"
                                  placeholder="Enter Zip code"
                                  name="delivery_zipcode"
                                  value={values.delivery_zipcode}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                              <div className="col-12 text-right">
                                <a
                                  className="mt-4 btn continue-zip-code-btn theme-btn"
                                  onClick={(e) => handleZipCode(e)}>
                                  Start Your Order
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Modal> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </RenderIf>

        <div className="container-fluid bg-light-blue" style={{ paddingTop: '50px' }}>
          <div className="row section-ml mt-4">
            <div className="col-md-6 col-sm-12 d-flex justify-content-end about-image">
              <img
                className="app-section-img-lg"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section.png"
                alt=""
              />
              <img
                className="app-section-img-sm"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section-sm.png"
                alt=""
              />
            </div>
            <div className="col-md-6 col-sm-12 d-flex flex-column justify-content-center fs-23 about-text">
              <p className="about-us-heading fw-600 d-center">
                Download our app
                <div className="land-divider"></div>
              </p>

              <p className="mt-3 text-left">
                Stress-free moving and storage solutions in the palm of your hand
              </p>
              <div className="row mt-3">
                <div className="col-md-3  app-download-button">
                  <img src="img/GooglePlay.png" alt="" />
                </div>
                <div className="col-md-3  app-download-button">
                  <img src="img/AppStore.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row section-xs " style={{ marginTop: '10px', paddingBottom: '30px' }}>
            <p className="about-us-heading fw-600 d-center">
              Download our app
              <div className="land-divider"></div>
            </p>
            <p className="mt-3 text-center">
              Stress-free moving and storage solutions in the palm of your hand
            </p>

            <div className="col-12  d-flex justify-content-end about-image">
              <img
                className="app-section-img-lg"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section.png"
                alt=""
              />
              <img
                className="app-section-img-sm"
                src="https://bluebox-s3.fra1.digitaloceanspaces.com/bluebox-frontend/static_media/App-section-sm.png"
                alt=""
              />
            </div>
            <div className="col-12  d-flex flex-column justify-content-center fs-23 about-text">
              <div className="row mt-3">
                <div className="col-md-6 col-sm-12 app-download-button">
                  <img src="img/GooglePlay.png" alt="" />
                </div>
                <div className="col-md-6 col-sm-12 app-download-button app-store-btn">
                  <img src="img/AppStore.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="learn-more pt-4 pb-4">
          <div className="content learn-more-dt">
            <div className="row lear-more-content-row">
              <div className="col-6 learn-more-content">
                <p className="fs-24 fw-600 mb-title">Take your time to settle in!</p>
                <p className="fs-15 fw-400 mb-description">
                  Don’t rush to unpack. Store your items easily with our Storage On-Demand service
                  Say goodbye to clutter and enjoy some peace of mind after your move.
                </p>
                <button className="btn theme-btn btn-learn-more" onClick={handleLearnMore}>
                  Learn more
                </button>
              </div>
              <div className="col-6">
                <img className="img img-fluid" src="img/storage-interface.png" />
              </div>
            </div>
          </div>
          <div className="content learn-more-mobile">
            <div className="row inner-content">
              <p className="fs-24 fw-600 mb-title">Take your time to settle in!</p>
              <p className="fs-15 fw-400 mb-description">
                Don’t rush to unpack. Store your items easily with our Storage On-Demand service.
                Say goodbye to clutter and enjoy some peace of mind after your move.
              </p>
            </div>
            <div className="row inner-content">
              <img className="img img-fluid" src="img/storage-interface.png" />
            </div>
            <div className="row inner-content">
              <button className="btn theme-btn btn-learn-more" onClick={handleLearnMore}>
                Learn more
              </button>
            </div>
          </div>
        </section>

        <br />
        {/* <!-- we deliver to this area --> */}
      </div>
    </>
  );
}
MovingBoxes.propTypes = {
  setShowHideHeader: PropTypes.any,
  setshowHideFooter: PropTypes.any,
  setshowHideinnerFooter: PropTypes.any,
  zipCodeSection: PropTypes.any,
  scrollToSection: PropTypes.any,
  setshowZipModal: PropTypes.any,
  storeStatus: PropTypes.any,
  storeStatusMessage: PropTypes.any
};
