import Slider from 'react-slick';
import qoutes from '../../assets/images/quotes.png';
import { useState } from 'react';

export default function SimpleSlider(testimonials) {
  const [isReadMore, setIsReadMore] = useState(
    Array(testimonials?.testimonials?.results?.length).fill(false)
  );

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const toggleReadMore = (index) => {
    const updatedIsReadMore = [...isReadMore];
    updatedIsReadMore[index] = !updatedIsReadMore[index];
    setIsReadMore(updatedIsReadMore);
  };

  return (
    <Slider {...settings}>
      {testimonials.testimonials.results?.map((obj, i) => {
        return (
          <div key={i} className="slide">
            <div className="card position-relative">
              <div className="card-body py-4 px-3">
                <div className="card-img">
                  <img src={qoutes} className="img-fluid" alt="q" width="32" height="22" />
                </div>
                <div
                  className={isReadMore[i] ? 'card-content read-more' : 'card-content'}
                  dangerouslySetInnerHTML={{ __html: obj.content }}></div>
                <div className="d-flex justify-content-end">
                  <a className="read-more-btn" onClick={() => toggleReadMore(i)}>
                    {isReadMore[i] ? '..Read less' : 'Read more.. '}
                  </a>
                </div>

                <div className="card-content-rating">
                  <i className="fa fa-star text-warning"></i>
                  <i className="fa fa-star text-warning"></i>
                  <i className="fa fa-star text-warning"></i>
                  <i className="fa fa-star text-warning"></i>
                  <i className="fa fa-star text-warning"></i>
                </div>
                <div>
                  <p className="card-content-name">{obj.name}</p>
                  <p className="fs-13">{obj?.occupation}</p>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}
